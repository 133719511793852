import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "../context/ProductContext";
import { Product } from "../models/Product";
import { toast } from "react-toastify";
import { Category } from "../models/Category";
import { Breadcrumbs, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ShareIcon from "@mui/icons-material/Share";
import { useShoppingList } from "../context/ShoppingListContext";

import "../styles/pages/product.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import ProductQuestionaire from "../forms/InquiryForm";
import ReadOnlyRichTextDisplay from "../components/global/ReadOnlyRichTextDisplay";

function ProductPage() {
  const { name } = useParams();
  const { getProductByName } = useProduct();

  const [product, setProduct] = React.useState<Product | null>(null);
  const [categoryAncestry, setCategoryAncestry] = React.useState<Category[]>(
    []
  );

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const { addToShoppingList } = useShoppingList();

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: product?.name,
          text: `Sprawdź ten produkt: ${product?.name}`,
          url: window.location.href,
          files: [
            new File(
              [await (await fetch(product?.primaryImage!)).blob()],
              `${product?.name}.jpg`,
              { type: "image/jpeg" }
            ),
          ],
        });
        toast.success("Produkt udostępniony!");
      } catch (error) {
        console.error("Error sharing the product", error);
        toast.info("Produkt nie został udostępniony.");
      }
    } else {
      handleCopyLink();
    }
  };

  const handleCopyLink = () => {
    const shareText = `Check out this product: ${product?.name}\n${window.location.href}\n\nImage:\n${product?.primaryImage}`;

    navigator.clipboard.writeText(shareText).then(
      () => {
        toast.success("Link copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy the text to clipboard", err);
        toast.error("Failed to copy the link.");
      }
    );
  };

  React.useEffect(() => {
    const fetchProduct = async () => {
      const toastId = toast.loading("Ładowanie produktu...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });

      try {
        const response = await getProductByName(name!);
        setProduct(response.product);
        setCategoryAncestry(response.categoryAncestry);
        toast.dismiss(toastId);
      } catch (error: any) {
        console.error("Błąd podczas ładowania produktu", error);
        toast.update(toastId, {
          render: "Błąd podczas ładowania produktu",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    };

    fetchProduct();
  }, [name]);

  React.useEffect(() => {
    if (product) {
      document.title = `Eltimex - ${product.name}`;
    }
  }, [product]);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Button
          color="secondary"
          variant="text"
          style={{ fontSize: "small", padding: "2px" }}
          onClick={() => {
            navigate("/szukaj");
          }}
        >
          Kategorie główne
        </Button>
        {categoryAncestry?.map((category) => (
          <Button
            key={category.id}
            color="primary"
            variant="text"
            style={{ fontSize: "small", padding: "2px" }}
            onClick={() => navigate("/szukaj?categoryId=" + category.id)}
          >
            {category.name}
          </Button>
        ))}
      </Breadcrumbs>
      <div className="flex flex-col md:grid md:grid-cols-5 md:gap-3 space-y-2 mt-5">
        <div className="col-span-3">
          <h1 className="text-3xl font-bold">{product?.name}</h1>
          <div className="w-full h-full">
            {product && (
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true, dynamicBullets: true }}
                zoom={{ maxRatio: 3 }}
                loop
                modules={[Navigation, Pagination, Zoom]}
                height={500}
                style={{ width: "100%", height: "500px" }}
              >
                {product && (
                  <>
                    <SwiperSlide key="primary">
                      <div className="swiper-zoom-container">
                        <img
                          src={product.primaryImage}
                          alt={product.name}
                          loading="lazy"
                          className="swiper-lazy centered-image"
                        />
                        <div className="swiper-lazy-preloader"></div>
                      </div>
                    </SwiperSlide>

                    {product.secondaryImages.map((image) => (
                      <SwiperSlide key={image.id}>
                        <div className="swiper-zoom-container">
                          <img
                            src={image.image}
                            alt={`${product.name} - ${image.id}`}
                            loading="lazy"
                            className="swiper-lazy centered-image"
                          />
                          <div className="swiper-lazy-preloader"></div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            )}
            <p className="text-sm text-gray-500 mt-2 w-full">
              Kliknij dwukrotnie na zdjęcie, aby powiększyć
            </p>
          </div>
        </div>
        <div className="col-span-2 space-y-2 ~px-4/8 flex flex-col justify-between">
          <div>
            <Link
              className="flex items-center space-x-2 hover:drop-shadow-2xl"
              to={`/producent/${product?.producer.name}`}
            >
              <p>
                Producent:{" "}
                <span className="font-bold">{product?.producer.name}</span>
              </p>
              {product?.producer.image && (
                <img
                  src={product?.producer.image}
                  alt={product?.producer.name}
                  height={125}
                  width={125}
                />
              )}
            </Link>
            <p className="">
              Numer seryjny/Kod produktu:{" "}
              <span className="font-bold">{product?.serialNumber}</span>
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">
              Proponowana cena: {product?.price.toFixed(2)} zł
            </h2>
            <h2 className="text-xl text-gray-500">
              Cena netto: {(product?.price! * 0.77).toFixed(2)} zł
            </h2>
            <p className="text-sm text-gray-500">
              Proponowana cena może ulec zmianie w zależności od ilości oraz
              warunków dostawy. Cena netto jest ceną bez VAT.
            </p>
          </div>
          <div>
            <p className="mb-5">
              Zapytaj o ten produkt lub dodaj do listy zakupowej aby złożyć
              zapytanie o więcej produktów.
            </p>
            <button
              className="p-3 rounded-md w-full bg-primary text-white flex items-center justify-center space-x-2 mt-2 hover:bg-secondary"
              onClick={() => {
                addToShoppingList(product!);
              }}
            >
              Dodaj do listy zakupowej
              <ShoppingBagIcon />
            </button>
            <button
              className="p-3 rounded-md w-full bg-background text-white flex items-center justify-center space-x-2 mt-2 hover:bg-secondary"
              onClick={handleDialogOpen}
            >
              Zapytaj o produkt
              <QuestionAnswerIcon />
            </button>
            <p className="text-sm text-gray-500 mt-2">
              Złożenie zapytania oznacza, że prześlemy ci ofertę cenową oraz
              warunki dostawy na podany przez ciebie adres email. Jeżeli masz
              inne pytania , skontaktuj się z nami telefonicznie lub mailowo.
            </p>
            <div className="flex items-center justify-around mt-4 border-t-2 pt-2">
              <a
                href="mailto:kontakt@eltimex.pl"
                className="text-sm text-gray-500 hover:underline"
              >
                Napisz do nas
                <EmailIcon />
              </a>
              <a
                href="tel:+48604856094"
                className="text-sm text-gray-500 hover:underline"
              >
                Zadzwoń do nas
                <PhoneIcon />
              </a>
              <button
                className="text-sm text-gray-500 hover:underline flex items-center"
                onClick={handleShare}
              >
                Udostępnij
                <ShareIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h2 className="text-2xl font-bold">Opis produktu</h2>
        <ReadOnlyRichTextDisplay content={product?.description!} />
      </div>
      <div className="mt-5">
        <h2 className="text-2xl font-bold">Parametry techniczne</h2>
        <table className="table-fixed w-full">
          <tbody>
            {product?.parameterValues.map((parameter, index) => (
              <tr
                key={
                  parameter.productId.toString() +
                  parameter.parameterId.toString()
                }
                className={index % 2 === 0 ? "bg-gray-100" : ""}
              >
                <td className="border px-4 py-2 text-right">
                  {parameter.parameter.name}
                </td>
                <td className="border px-4 py-2">{parameter.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ProductQuestionaire
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        products={[
          {
            productId: product?.id!,
            quantity: 1,
          },
        ]}
        singleProduct={true}
      />
    </div>
  );
}

export default ProductPage;
