import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Category } from "../../models/Category";
import { useNavigate } from "react-router-dom";
import { Bolt } from "@mui/icons-material";

type Props = {
  categories: Category[];
};

function NavBarCategories(props: Props) {
  const navigate = useNavigate();

  return (
    <div className="mt-2 flex w-full transition-all duration-300 ~sm/lg:text-xs/base space-x-2 divide-x divide-background">
      <div className="p-3 flex items-center space-x-1">
        <button
          className="hover:drop-shadow-xl whitespace-nowrap"
          onClick={() => {
            navigate("/szukaj");
          }}
        >
          Kategorie główne
        </button>
      </div>
      <Swiper
        slidesPerView="auto"
        grabCursor={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true,
        }}
        loop
        modules={[Autoplay, Pagination, Navigation, FreeMode]}
      >
        {props.categories?.map((category) => (
          <SwiperSlide
            key={category.id}
            style={{ width: "auto", height: "auto", zIndex: 1 }}
          >
            <button
              key={category.id}
              className="hover:drop-shadow-xl nav-category-button p-3 shrink-0"
              onClick={() => {
                navigate("/szukaj?categoryId=" + category.id);
              }}
            >
              <Bolt color="secondary" sx={{ fontSize: 13, marginRight: 1 }} />
              {category.name}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default NavBarCategories;
