import React from "react";
import { Category } from "../models/Category";
import { Parameter } from "../models/Parameter";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Filters from "./Filters";

type Props = {
  currentCategory: Category | null;
  categories: Category[];
  parameters: Parameter[];
  // producers: Producer[];
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  // urlProducers: string | null;
  filters: string | null;
  priceRange: number[];
  currentPrice: number[];
  setCurrentPrice: (range: number[]) => void;
  setIsSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SearchSidePanel({
  currentCategory,
  categories,
  parameters,
  // producers,
  searchParams,
  setSearchParams,
  // urlProducers,
  filters,
  priceRange,
  currentPrice,
  setCurrentPrice,
  setIsSidePanelOpen,
}: Props) {
  return (
    <div className="w-full p-5 divide-y space-y-4">
      <div>
        {currentCategory && (
          <button
            className="hover:text-primary hover:underline hover:drop-shadow-md"
            key={
              currentCategory?.parentCategory
                ? currentCategory.parentCategory.id
                : "Wszystkie"
            }
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams);
              currentCategory?.parentCategory
                ? newSearchParams.set(
                    "categoryId",
                    currentCategory.parentCategory.id.toString()
                  )
                : newSearchParams.delete("categoryId");
              setSearchParams(newSearchParams);
            }}
          >
            <ChevronLeft />
            {currentCategory?.parentCategory
              ? currentCategory?.parentCategory.name
              : "Wszystkie"}
          </button>
        )}
        <p className="font-semibold">
          {currentCategory ? currentCategory.name : "Wszystkie produkty"}{" "}
        </p>
        <div className="flex flex-col space-y-2 items-start mt-2 ml-4">
          {categories.map((category: Category) => (
            <button
              className="hover:text-primary hover:underline hover:drop-shadow-md"
              key={category.id}
              onClick={() => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("categoryId", category.id.toString());
                setSearchParams(newSearchParams);
              }}
            >
              {category.name}
              <ChevronRight />
            </button>
          ))}
        </div>
      </div>
      <Filters
        parameters={parameters}
        // producers={producers}
        // encodedProducers={urlProducers!}
        encodedFilters={filters!}
        setPriceRange={setCurrentPrice}
        currentPriceRange={currentPrice}
        totalPriceRange={priceRange}
        handleSaveFilters={(filters) => {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set("filters", filters);
          // newSearchParams.set("producers", producers);
          newSearchParams.set("minPrice", currentPrice[0].toString());
          newSearchParams.set("maxPrice", currentPrice[1].toString());
          setSearchParams(newSearchParams);
          setIsSidePanelOpen(false);
        }}
      />
    </div>
  );
}

export default SearchSidePanel;
