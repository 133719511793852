import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer p-5 mt-10 text-sm">
      <div className="w-full flex justify-evenly md:divide-x-2 flex-col md:flex-row divide-y-2 md:divide-y-0">
        <div className="w-full p-2 px-6">
          <h2 className="text-xl font-bold mb-2 text-primary">O nas</h2>
          <p>
            Eltimex to firma zajmująca się sprzedażą elektroniki od 2002 roku. W
            naszym sklepie znajdziesz szeroki wybór produktów w atrakcyjnych
            cenach. Zapraszamy do zakupów!
          </p>
        </div>
        <div className="w-full p-2 px-6">
          <h2 className="text-xl font-bold mb-2 text-primary">
            Przydatne linki
          </h2>
          <ul>
            <li className="hover:underline">
              <Link to="/szukaj">Katalog produktów</Link>
            </li>
            {/* <li className="hover:underline">
              <a href="/about">O nas</a>
            </li> */}
            <li className="hover:underline">
              <Link to="/regulamin">Regulamin</Link>
            </li>
            {/* <li className="hover:underline">
              <a href="/privacy">Polityka prywatności</a>
            </li> */}
          </ul>
        </div>
        <div className="w-full p-2 px-6">
          <h2 className="text-xl font-bold mb-2 text-primary">
            Skontaktuj się z nami
          </h2>
          <div className="flex flex-col space-y-2">
            <a
              href="mailto:kontakt@eltimex.pl"
              className="text-gray-500 hover:underline"
            >
              Napisz do nas kontakt@eltimex.pl
              <EmailIcon />
            </a>
            <a
              href="mailto:eltimex@vp.pl"
              className="text-gray-500 hover:underline"
            >
              Napisz do nas eltimex@vp.pl
              <EmailIcon />
            </a>
            <a
              href="tel:+48604856094"
              className="text-gray-500 hover:underline"
            >
              Zadzwoń do nas +48 604 856 094
              <PhoneIcon />
            </a>
          </div>
        </div>
        <div className="w-full p-2 px-6">
          <h2 className="text-xl font-bold mb-2 text-primary">Adres</h2>
          <p>
            Eltimex
            <br />
            ul. Nadrzeczna 1
            <br />
            42-360 Poraj
            <br />
            Polska, Śląskie
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} Eltimex - Wszelkie prawa zastrzeżone
      </div>
    </footer>
  );
}

export default Footer;
