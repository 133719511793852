import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { Producer } from "../models/Producer";
import { withAuth } from "../middleware/withAuth";

interface ProducerContextType {
  createProducer: (
    name: string,
    description?: string,
    image?: File | null
  ) => Promise<void>;
  editProducer: (
    id: number,
    name: string,
    description?: string,
    image?: File | null
  ) => Promise<void>;
  getProducers: (
    page?: number,
    limit?: number,
    query?: string
  ) => Promise<{
    producers: Producer[];
    totalProducers: number;
    totalPages: number;
    currentPage: number;
  }>;
  getProducer: (id: number) => Promise<Producer>;
  getProducerByName: (name: string) => Promise<Producer>;
  deleteProducer: (id: number) => Promise<void>;
  getProducersForCategory: (categoryId: number) => Promise<Producer[]>;
  getProducersWithImages: () => Promise<Producer[]>;
}

const ProducerContext = createContext<ProducerContextType | undefined>(
  undefined
);

export const useProducer = () => {
  const context = useContext(ProducerContext);
  if (!context) {
    throw new Error("useProducer must be used within a ProducerProvider");
  }
  return context;
};

interface ProducerProviderProps {
  children: ReactNode;
}

export const ProducerProvider: React.FC<ProducerProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const getProducersWithImages = async () => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/producers/display`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch producers with images", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProducersForCategory = async (categoryId: number) => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/producers/category/${categoryId}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch producers for category", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const deleteProducer = async (id: number) => {
    try {
      const config = await withAuth({
        method: "delete",
        url: `${apiUrl}/api/producers/producer/${id}`,
      });
      await axios(config);
      await getProducers();
    } catch (error: any) {
      console.error("Failed to delete producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProducers = async (
    page?: number,
    limit?: number,
    query?: string
  ): Promise<{
    producers: Producer[];
    totalProducers: number;
    totalPages: number;
    currentPage: number;
  }> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/producers`,
        params: { page, limit, search: query },
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch producers", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProducer = async (id: number): Promise<Producer> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/producers/${id}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProducerByName = async (name: string): Promise<Producer> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/producers/name/${encodeURI(name)}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const createProducer = async (
    name: string,
    description?: string,
    image?: File | null
  ) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      if (description) formData.append("description", description);
      if (image) formData.append("image", image);

      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/producers/producer`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });
      await axios(config);
      await getProducers();
    } catch (error: any) {
      console.error("Failed to create producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const editProducer = async (
    id: number,
    name: string,
    description?: string,
    image?: File | null
  ) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      if (description) formData.append("description", description);
      if (image) formData.append("image", image);

      const config = await withAuth({
        method: "put",
        url: `${apiUrl}/api/producers/producer/${id}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });
      await axios(config);
      await getProducers();
    } catch (error: any) {
      console.error("Failed to edit producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <ProducerContext.Provider
      value={{
        getProducersForCategory,
        getProducersWithImages,
        createProducer,
        getProducers,
        getProducerByName,
        getProducer,
        deleteProducer,
        editProducer,
      }}
    >
      {children}
    </ProducerContext.Provider>
  );
};
