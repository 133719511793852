import React from "react";
import { Offer } from "../models/Offer";
import { Button, CardActions } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  offer: Offer;
};

function OfferCard({ offer }: Props) {
  const isValid = new Date(offer.validUntil).getTime() > Date.now();  

  return (
    <div className={`shadow-sm rounded-md p-2 ${!isValid ? "bg-red-100" : ""}`}>
      <div className="w-full flex items-center justify-between">
        <div>
          <p>
            Data złożenia oferty:{" "}
            {new Date(offer.sentAt).toLocaleString("pl-PL")}
          </p>
          <p>
            Data ważnośći oferty:{" "}
            {new Date(offer.validUntil).toLocaleString("pl-PL")}
          </p>
        </div>
        {!isValid && <p className="text-red-500">Oferta wygasła</p>}
      </div>
      <div className="flex justify-between items-center w-full h-16 p-1">
        <div className="flex items-center space-x-2">
          <p className="font-semibold">Numer oferty: {offer.id}</p>
          <p className="text-gray-500">{offer.inquiry.name}</p>
          <p className="text-gray-500">{offer.inquiry.email}</p>
        </div>
        <div>
          <p className="mr-5">
            Łączna wartość oferty:{" "}
            <span className="font-semibold text-green-500">
              {offer.price.toFixed(2)} PLN
            </span>
          </p>
        </div>
        <CardActions>
          <Button
            variant="text"
            color="primary"
            size="small"
            style={{ fontSize: "small", padding: "2px" }}
            component={Link}
            to={`/oferta/${offer.id}`}
          >
            Zobacz szczegóły oferty
          </Button>
        </CardActions>
      </div>
    </div>
  );
}

export default OfferCard;
