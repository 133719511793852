import React from "react";
import { EditorState, ContentState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fontSizeStyleMap } from "./RichTextEditor";

type ReadOnlyRichTextDisplayProps = {
  content: string;
};

const ReadOnlyRichTextDisplay: React.FC<ReadOnlyRichTextDisplayProps> = ({
  content,
}) => {
  let editorState = EditorState.createEmpty();

  if (content) {
    try {
      const contentState = convertFromRaw(JSON.parse(content));
      editorState = EditorState.createWithContent(contentState);
    } catch (e) {
      const contentState = ContentState.createFromText(content);
      editorState = EditorState.createWithContent(contentState);
    }
  }

  return (
    <Editor
      editorState={editorState}
      readOnly={true}
      toolbarHidden={true}
      customStyleMap={fontSizeStyleMap}
    />
  );
};

export default ReadOnlyRichTextDisplay;
