import React from "react";
import { IconButton, MenuItem, Select } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

type Props = {
  setIsSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSidePanelOpen: boolean;
  displayMode: string;
  setDisplayMode: React.Dispatch<React.SetStateAction<"grid" | "list">>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
};

function SearchUtilities(props: Props) {
  const location = window.location.pathname;
  const width = window.innerWidth;

  return (
    <div className="flex justify-end items-start mb-4">
      <div className="flex items-center space-x-2 divide-x-2 pb-1 transition-all">
        {width <= 768 && location.includes("szukaj") && (
          <div className="flex flex-col items-center px-2">
            <p className="text-gray-700 ~text-xs/base">Filtry</p>
            <IconButton
              onClick={() => props.setIsSidePanelOpen(!props.isSidePanelOpen)}
              color="primary"
            >
              <TuneIcon sx={{ fontSize: width <= 768 ? 25 : 40 }} />
            </IconButton>
          </div>
        )}
        {width > 400 && (
          <div className="flex flex-col items-center px-2">
            <p className="text-gray-700 ~text-xs/base">Wygląd</p>
            <IconButton
              onClick={() => {
                props.setDisplayMode(
                  props.displayMode === "grid" ? "list" : "grid"
                );
              }}
              color="primary"
            >
              {props.displayMode === "grid" ? (
                <ViewModuleIcon sx={{ fontSize: width <= 768 ? 25 : 40 }} />
              ) : (
                <ViewListIcon sx={{ fontSize: width <= 768 ? 25 : 40 }} />
              )}
            </IconButton>
          </div>
        )}
        <div className="flex flex-col items-center px-2">
          <p className="text-gray-700 ~text-xs/base">Limit</p>
          <Select
            id="limit"
            value={props.limit}
            onChange={(e) => props.setLimit(e.target.value as number)}
            style={{ padding: "0px 0px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default SearchUtilities;
