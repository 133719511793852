import { CloseOutlined } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { useShoppingList } from "../context/ShoppingListContext";
import { Tooltip } from "react-tooltip";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import InquiryForm from "../forms/InquiryForm";

function ShoppingList({
  isShoppingListOpen,
  setIsShoppingListOpen,
}: {
  isShoppingListOpen: boolean;
  setIsShoppingListOpen: (isOpen: boolean) => void;
}) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const {
    shoppingList,
    getShoppingList,
    setProductQuantity,
    removeProductFromShoppingList,
  } = useShoppingList();

  useEffect(() => {
    getShoppingList();
  }, []);
  
  const slideStyle = useSpring({
    transform: isShoppingListOpen ? "translateX(0%)" : "translateX(100%)",
    config: { tension: 300, friction: 40 },
  });

  const shoppingListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shoppingListRef.current &&
        !shoppingListRef.current.contains(event.target as Node)
      ) {
        setIsShoppingListOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsShoppingListOpen]);

  return (
    <animated.div
    className="w-[400px] max-w-[80vw]"
      ref={shoppingListRef}
      style={{
        ...slideStyle,
        position: "fixed",
        zIndex: 1000,
        padding: "1rem",
        right: 0,
        top: 0,
        height: "100vh",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
      }}
    >
      <IconButton
        onClick={() => setIsShoppingListOpen(false)}
        style={{ marginBottom: "20px" }}
      >
        <CloseOutlined />
      </IconButton>
      <div className="flex flex-col space-y-3">
        {shoppingList.length > 0 ? (
          shoppingList.map((product) => (
            <div
              className="flex flex-col space-y-2"
              key={product.id}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={product.name}
            >
              <div className="flex items-start justify-between">
                <div className="flex items-center space-x-2">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="h-16 w-16 object-contain object-center"
                  />
                  <div className="flex flex-col">
                    <Link
                      className="hover:underline"
                      to={`/produkt/${product.slug}`}
                      onClick={() => setIsShoppingListOpen(false)}
                    >
                      {product.name.length > 40
                        ? `${product.name.slice(0, 40)}...`
                        : product.name}
                    </Link>
                    <p>{product.price} zł</p>
                  </div>
                </div>
                <IconButton
                  onClick={() => removeProductFromShoppingList(product.id)}
                  style={{ padding: 0 }}
                  color="error"
                >
                  <CloseOutlined />
                </IconButton>
              </div>
              <TextField
                type="number"
                label="Ilość"
                size="small"
                defaultValue={product.quantity}
                onChange={(e) =>
                  setProductQuantity(product.id, parseInt(e.target.value))
                }
              />
            </div>
          ))
        ) : (
          <p>Brak produktów w liście zakupowej</p>
        )}
      </div>
      <button
        className={`p-3 rounded-md w-full bg-background text-white flex items-center justify-center space-x-2 hover:bg-secondary mt-24
          ${
            shoppingList.length === 0
              ? "cursor-not-allowed bg-opacity-20 hover:bg-opacity-20"
              : ""
          }
          `}
        onClick={handleDialogOpen}
        disabled={shoppingList.length === 0}
      >
        Zapytaj o produkty z listy
        <QuestionAnswerIcon />
      </button>
      <InquiryForm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        products={shoppingList.map((product) => ({
          productId: product.id,
          quantity: product.quantity,
        }))}
        singleProduct={false}
      />
      <Tooltip id="my-tooltip" />
    </animated.div>
  );
}

export default ShoppingList;
