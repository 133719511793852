import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import { ThemeProvider } from "@mui/material";
import muiTheme from "./muiTheme";
import NavBar from "./components/navbar/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register";
import Admin from "./pages/Admin";
import ProductPage from "./pages/ProductPage";
import Footer from "./components/Footer";
import Search from "./pages/Search";
import CombinedProvider from "./context/CombinedProvider";
import Offer from "./pages/Offer";
import ProducerPage from "./pages/ProducerPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import Reload from "./components/Reload";
import Cookies from "./components/Cookies";

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <CombinedProvider>
        <Cookies />
        <NavBar />
        {/* <div className="bg-orange-600 flex items-center space-x-1 justify-center">
          <h3 className="text-white text-2xl font-bold p-5 text-center">
            Strona w trakcie budowy
          </h3>
        </div> */}
        <div className="p-4 ml-auto mr-auto w-full max-w-screen-2xl">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/admin/*"
              element={
                <PrivateRoute checkRole={"Admin"}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route path="/logowanie" element={<Login />} />
            <Route path="/zapomnialem-hasla" element={<ForgotPassword />} />
            <Route path="/resetuj-haslo/:token" element={<ResetPassword />} />
            <Route path="/rejestracja" element={<Register />} />
            <Route path="/potwierdz-email/:token" element={<ConfirmEmail />} />
            <Route path="/produkt/:name" element={<ProductPage />} />
            <Route path="/producent/:name" element={<ProducerPage />} />
            <Route path="/oferta/:id" element={<Offer />} />
            <Route path="/szukaj" element={<Search />} />
            <Route path="/regulamin" element={<TermsAndConditions />} />
          </Routes>
        </div>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
      </CombinedProvider>
    </ThemeProvider>
  );
}

export default App;
