import * as React from "react";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProduct } from "../context/ProductContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Chip } from "@mui/material";
import { Product } from "../models/Product";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useShoppingList } from "../context/ShoppingListContext";
import "react-tooltip/dist/react-tooltip.css";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductCard({
  product,
  inDisplayMode = false,
  horizontal = false,
}: {
  product: Product;
  inDisplayMode: boolean;
  horizontal?: boolean;
}) {
  const [expanded, setExpanded] = React.useState(false);

  const { deleteProduct } = useProduct();
  const { addToShoppingList } = useShoppingList();

  const keywordList = product.keywords ? product.keywords.split(";") : [];

  const handleDeleteProduct = async () => {
    const toastId = toast.loading("Deleting...", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });

    try {
      await deleteProduct(product.id);
      toast.update(toastId, {
        render: "Product deleted!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: Error | any) {
      console.error("Failed to delete product", error);
      toast.update(toastId, {
        render: "Failed to delete product",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteClick = async () => {
    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteProduct(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`overflow-hidden bg-white ${
        horizontal ? "w-52 h-[304px] mt-2 mr-2 rounded-sm" : "w-full"
      } 
        shadow-md transition-all duration-100 hover:shadow-lg
        `}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={window.innerWidth > 768 ? product.name : ""}
    >
      <div
        className={`flex justify-between items-center w-full p-2 ${
          horizontal ? "flex-col h-full" : "flex-row h-32"
        }`}
      >
        <div
          className={`flex items-center space-x-2 ${
            horizontal ? "flex-col justify-between h-full" : "flex-row"
          }`}
        >
          <Link to={`/produkt/${product.slug}`}>
            <div
              className={`overflow-hidden rounded-sm h-32 flex items-center justify-center ${
                horizontal ? "w-full" : "w-32"
              }`}
            >
              <img
                src={product.mobilePrimaryImage}
                alt={product.name}
                className={`h-32 object-contain object-top ${
                  horizontal ? "w-full" : "w-32"
                }`}
              />
            </div>
          </Link>
          <div
            className={`flex flex-col ${
              horizontal ? "justify-between h-40 w-full" : ""
            }`}
          >
            <Link
              to={`/produkt/${product.slug}`}
              className={`hover:underline ${
                horizontal ? "text-base" : "text-lg"
              }`}
            >
              {product.name.length > 40
                ? `${product.name.slice(0, 40)}...`
                : product.name}
            </Link>
            {inDisplayMode ? (
              <div className="flex items-center justify-between p-1">
                <div className="mt-2">
                  <h2 className="font-bold">
                    {horizontal ? "Cena" : "Proponowana cena"}:{" "}
                    {product?.price.toFixed(2)} zł
                  </h2>
                  <h2 className="text-sm text-gray-500">
                    {horizontal ? "Netto" : "Cena netto"}:{" "}
                    {(product?.price! * 0.77).toFixed(2)} zł
                  </h2>
                </div>
                {inDisplayMode && horizontal && (
                  <button
                    className="p-3 rounded-full hover:bg-primary hover:text-white text-primary flex items-center justify-center transition-all duration-100"
                    onClick={() => {
                      try {
                        addToShoppingList(product);
                      } catch (error) {
                        console.error(
                          "Failed to add product to shopping list",
                          error
                        );
                      }
                    }}
                  >
                    <ShoppingBagIcon style={{ fontSize: 25 }} />
                  </button>
                )}
              </div>
            ) : (
              <div>
                <Typography variant="body2" color="textSecondary">
                  ${product.price.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.serialNumber}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {!inDisplayMode && (
          <div className="flex flex-col items-end">
            <Chip
              label={product.tag}
              color={
                product.tag === "New"
                  ? "success"
                  : product.tag === "Sale"
                  ? "warning"
                  : "default"
              }
              style={{ marginBottom: "8px" }}
            />
            <Typography variant="body2" color="textSecondary">
              {product.popularity}
            </Typography>
          </div>
        )}
        {!inDisplayMode && (
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <IconButton
              aria-label="edit"
              component={Link}
              to={`/admin/products/edit/${product.id}`}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="warning"
              onClick={handleDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          </CardActions>
        )}
        {inDisplayMode && !horizontal && (
          <button
            className="p-3 rounded-full hover:bg-primary hover:text-white text-primary flex items-center justify-center transition-all duration-100"
            onClick={() => {
              try {
                addToShoppingList(product);
              } catch (error) {
                console.error("Failed to add product to shopping list", error);
              }
            }}
          >
            <ShoppingBagIcon style={{ fontSize: 25 }} />
          </button>
        )}
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <p>Słowa kluczowe: </p>
          {keywordList.map((keyword, index) => (
            <Chip
              key={index}
              label={keyword}
              style={{ marginRight: "8px", marginBottom: "8px" }}
            />
          ))}
          <p>Opis: </p>
          <Typography paragraph>
            {product.description ? product.description : "Brak opisu"}
          </Typography>
        </CardContent>
      </Collapse>
    </div>
  );
}
