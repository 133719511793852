import React, { createContext, useContext, ReactNode, useState } from "react";

interface SearchContextType {
  isSidePanelOpen: boolean;
  setIsSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  return (
    <SearchContext.Provider
      value={{
        isSidePanelOpen,
        setIsSidePanelOpen,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
