import { Button, TextField } from "@mui/material";
import React from "react";

type Props = {
  terms: string;
  setTerms: React.Dispatch<React.SetStateAction<string>>;
  saveTerms: () => void;
};

function Terms({ terms, setTerms, saveTerms }: Props) {
  return (
    <div className="">
      <h2 className="text-2xl font-bold mb-2">Regulamin sklepu</h2>
      {terms.length === 0 && (
        <p className="text-red-500 mb-5">
          UWAGA! Brak regulaminu sklepu. Proszę o dodanie treści.
        </p>
      )}
      <TextField
        label="Treść regulaminu"
        variant="outlined"
        multiline
        fullWidth
        rows={12}
        value={terms}
        onChange={(e) => setTerms(e.target.value)}
        className="mt-2"
      />
      <Button
        variant="outlined"
        color="success"
        style={{ padding: "5px 10px", marginTop: "10px" }}
        onClick={() => saveTerms()}
      >
        Zapisz regulamin
      </Button>
    </div>
  );
}

export default Terms;
